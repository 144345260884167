import { PageLayout } from "@components";
import { PartnersV2 } from "@components/LimsSoftware/PartnersV2";
import { JoinInstitutionsV2 } from "../../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import React from "react";
import { Demo, GetWorkDone } from "../biopharmaceutical";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../../products/orders/orders.module.scss";
import Margins from "@components/Margins/Margins";
import classNames from "classnames";
import FeatureSummary from "@components/FeatureSummary/FeatureSummary";
import { FeatureROI } from "@assets/icons";
import OnboardWorkflowV4 from "@components/CentralizedPlatform/OnboardWorkflow";

export default function BiopharmaPage() {
	const demoProps = {
		title: "Accelerate your therapeutic R&D",
		subtitle:
			"Genemod’s biopharmaceutical R&D platform is trusted by hundreds of biopharmaceutical innovators to accelerate their R&D.",
		srcImg: "research-center-of-the-ecole-poly-technique.png",
		disableBackgroundShapes: true,
		rightSectionStylesOverride: styles.rightSectionStylesOverride,
		getADemoOverride: classNames(
			styles.getADemoOverride,
			styles.clinicalGetADemoOverride
		),
		titleStylesOverride: styles.CRODemoTitleStylesOverride,
		subtitleStylesOverride: styles.clinicalDemoSubtitleStylesOverride,
		leftSectionOverride: styles.clinicalDemoLeftSectionOverride,
	};
	const featureCardTwo: FeatureCardProps = {
		title: "The all-in-one notebook for research anytime, anywhere",
		list: [
			"Collaborative real-time editing",
			"Create, comment, and reply to protocols",
			"Centralize attachments and metadata",
		],
		sublist: [
			"We take teamwork to the next level by letting you and your team work together in real time on experiments.",
			"Make it a team effort with in-line and page comments and replies.",
			"Easily upload and manage relevant attachments within experiments, as well as create new sections.",
		],
		sublistColor: "text-helper",
		image: (
			<StaticImage
				src={"../../../assets/solutions/live-editing-file.png"}
				alt="Collaborative real-time editing"
			/>
		),
		className: styles.startupFeatureQuickAccess,
		listItemWeight: "SEMIBOLD",
		titleStylesOverride: styles.featureTitleStylesOverride,
	};
	const featureSummaryProps = {
		title: "Successful tech transfer with Genemod",
		subtitle:
			"Our Enterprise plans help companies ensure data integrity and improve collaboration institution-wide. Each subscription is a custom-built, expert-driven foundation of your lab automation program.",
		list: [
			"Experience a seamless tech transfer journey with Genemod by your side",
			"Built for 21 CFR Part 11 compliance",
		],
		sublist: [
			"Enterprise plans include everything you need to manage inventory and project data in one centralized place, plus expert-in-the-loop compliance & security services from a dedicated R&D enablement team.",
			"Easily manage version history without the need to save multiple protocol copies.",
		],
		img: <FeatureROI />,
		applyV4Styles: true,
		titleStylesOverride: styles.startupDemoFeatureSummaryTitle,
		sectionWrapper: styles.featureSummarySectionWrapper,
		contentOverride: styles.featureSummaryContentOverride,
		subtitleStyles: styles.startupDemoSubtitleStylesOverride,
		listStyles: styles.startupListStyles,
	};
	const getWorkDoneProps = {
		title: "How does “Powered by Genemod” work?",
		subtitle: "Genemod enables large institutions with multiple teams to centralize projects and experiments on one platform. Google Docs isn’t built for life science R&D - so we built a platform that is.",
		image: <OnboardWorkflowV4 style={{ width: "100%" }} />,
		showSquares: false,
		showCentralizedPlatformV2: true,
		showSubtitleBottom: false,
		titleWidth: 920,
		subtitleWidth:1040,
		applyV4Styles: true,
		sectionWrapper: styles.startupGetWorkSectionWrapper,
	};
	const getWorkDonePropsTwo = {
		title: "Where research teams get work done",
		subtitle:
			"We’ve built the first platform for life sciences R&D where project management meets electronic lab notebook.",
		showSquares: false,
		showCentralizedPlatformV2: true,
		showSubtitleBottom: true,
		titleWidth: 920,
		subtitleWidth: 1280,
		applyV4Styles: true,
	};
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "research" }}>
			<Margins className={styles.demoMargins}>
				<Demo {...demoProps} />
			</Margins>
			<Margins className={styles.getWorkDoneMargins}>
				<GetWorkDone {...getWorkDonePropsTwo} />
			</Margins>
			<FeatureSummary {...featureSummaryProps} />
			<Margins className={styles.featureMargins}>
				<FeatureCardLayout className={styles.featuresLayout}>
					<FeatureCard {...featureCardTwo} />
				</FeatureCardLayout>
			</Margins>
			<Margins className={styles.getWorkDoneMargins}>
				<GetWorkDone {...getWorkDoneProps} />
			</Margins>
			<div className={styles.ourPartners}>
				<PartnersV2 containerOverride={styles.containerOverride} />
			</div>
			<Margins className={styles.institutionsMargins}>
				<JoinInstitutionsV2
					outerContainerClassName={
						styles.institutionsOuterContainerClassName
					}
				/>
			</Margins>
		</PageLayout>
	);
}
